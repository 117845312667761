html,
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

html {
  background-color: black;
}

body {
  background-color: white;
}

.transition {
  transition: 2s;
}

body.dark {
  opacity: 0.2;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: 2s;
}

h1 {
  font-size: 40px;
  font-weight: normal;
  margin-bottom: 60px;
}

h1 div {
  font-size: 12px;
  width: 100%;
  text-align: right;
}

.movie-status {
  width: 100%;
  text-align: left;
  margin-left: 77px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  height: 15px;
  min-width: 1px;
}

.movie {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;
  margin-left: 50px;
}

.video {
  position: absolute;
  color: black;
  bottom: 250px;
  left: 20px;
  z-index: 10;
  font-size: 14px;
}

.video a {
  color: black;
}

.watermark {
  position: fixed;
  bottom: -70px;
  right: -70px;
  opacity: 0.3;
  height: 250px;
  cursor: pointer;
}

.love {
  position: fixed;
  color: black;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  font-size: 14px;
}

.love a {
  color: black;
}

.rotating {
  -webkit-animation: rotating 60s linear infinite;
  animation: rotating 60s linear infinite;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
